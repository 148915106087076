// Override default variables before the import
$body-bg: #efebe9;
$gradient: linear-gradient(
  180deg,
  rgba(239, 235, 233, 1) 0%,
  rgba(225, 225, 225, 1) 100%
);
$primary: #8a2435;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

body {
  padding-top: 81px;
}

a {
  color: white;
  text-decoration: none;
}

.landing-container {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100vh;
  background: url("assets/home.png") no-repeat center right;
}

@media only screen and (max-width: 600px) {
  .landing-container {
    align-items: start;
    background-position-x: 0px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .landing-container {
    align-items: start;
    background-position-x: left;
  }
}

.landing-block {
  padding: 20px;
  font-size: 14px;
  color: #8a2435;
  text-align: center;
  margin-left: 10vw;

  .tag-label {
    color: #8a2435;
    font-size: 20px;
    position: relative;
    padding: 0 5px;

    &::after,
    &::before {
      content: "";
      border: solid #8a2435;
      width: 4px;
      height: 0;
      position: absolute;
      border-width: 4px 3px 4px;
      top: 50%;
      margin-top: -4px;
    }

    &::after {
      left: -10px;
      border-left-color: transparent;
    }

    &::before {
      right: -10px;
      border-right-color: transparent;
    }
  }

  h1 {
    font-size: 72px;
    margin: 20px 0;
  }

  a.tel {
    color: #8a2435;
  }

  a:not(.tel) {
    display: inline-block;
    background-color: #8a2435;
    border: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    padding: 20px;
    line-height: 22px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .landing-block {
    margin: 0 auto;
  }
}

.historia {
  @media only screen and (min-width: 600px) {
    .texto-historia {
      p {
        margin: 0 0 20px 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
      border-left: 2px solid #8a2435;
    }
  }
  img {
    width: 80%;
    margin: 0 auto;
    display: block;
  }
  padding-bottom: 20px;
}

.product-card {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  display: flex;

  img {
    width: 100%;
    display: block;
    margin-bottom: 15px;
  }

  span {
    font-size: 12px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: #464646;
  }
}

@media only screen and (max-width: 600px) {
  .product-card {
    margin-bottom: 40px;
  }
}

p.lead {
  font-size: 18px;
}

h1 {
  color: #8a2435;
  font-size: 56px;
  padding: 0;
  font-family: "Brush Script", serif;
  font-weight: normal;
}

h2 {
  text-align: center;
  color: #8a2435;
  font-size: 28px;
  margin: 60px 0 40px;
  padding: 0;
  font-family: "Suez One", serif;
  font-weight: normal;
}

h3 {
  color: #8a2435;
  font-size: 22px;
  padding: 0;
  margin: 0;
  font-family: "Suez One", serif;
  font-weight: normal;
}

.header,
.footer-container {
  background-color: #8a2435;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu {
    flex-grow: 1;
  }

  .menu-mobile {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .menu {
      display: none;
    }
    .menu-mobile {
      display: flex;
    }
  }

  ul {
    display: inline-flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    li a {
      display: block;
      font-weight: 600;
      padding: 36px 20px;
    }

    @media only screen and (max-width: 600px) {
      li a {
        padding: 15px 20px;
      }
    }
  }
}

.header {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1;
  h1 {
    color: #ffffff;
    margin: 0;
    font-size: 46px;
  }
  p {
    margin: 0;
    color: #ffffff;
  }
}

.logo {
  h1 {
    color: #ffffff;
    font-size: 46px;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }
}

.footer {
  .container {
    flex-direction: column;
  }
  text-align: center;
  color: #ffffff;
  background: url("assets/footer.png") no-repeat center top;
  padding-top: 200px;
  font-size: 14px;
}

.contact {
  p svg {
    margin-right: 5px;
  }
}

button {
  background-color: #8a2435;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  padding: 20px;
  line-height: 22px;
}

.container {
  position: relative;
  padding: 0 20px;
  margin: 0 auto;
}
